@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin-top: 70px;
	/* smoth scrolling */
	scroll-behavior: smooth;
}

.heading-text {
	@apply text-3xl font-bold md:text-5xl xl:text-6xl;
}

.container {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 62%;
}
/* add media quesry when the size of the screen is greater than 768px then the padding-tp will be 25% in .container */
@media (min-width: 768px) {
	.container {
		padding-top: 25%;
	}
}

.responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	border: none;
}

/* scrollbar css change it color to  #15AABE and its bg to light #15AABE */

::-webkit-scrollbar {
	width: 5px;
}
::-webkit-scrollbar-track {
	background: #dee9f2;
}
::-webkit-scrollbar-thumb {
	background: #15aabed1;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #15aabed1;
}
/* also change the border radius of the scroll bar */
